
import { defineComponent, toRefs, watch, ref } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";

export default defineComponent({
  components: { GoogleMap, Marker },
  emits: ["changeLocation"],
  props: {
    latitude: {
      type: String,
      required: false,
      default: "",
    },
    longitude: {
      type: String,
      required: false,
      default: "",
    },
    markersM:{
      type: Array,
      required: false,
      default: [],
    },
  },
  setup(props, { emit }) {
    let latitudeW = toRefs(props).latitude;
    let longitudeW = toRefs(props).longitude;
    let latInitial = "19.45427274236404";
    let lngInitial = "-99.13482926287841";
    let markers_w = toRefs(props).markersM;

    const mapsKey = ref<any>("AIzaSyACDXm5WBEngJZHLoEiSzzYCdlkg3THQmI");
    const center = { lat: parseFloat(latInitial), lng: parseFloat(lngInitial) };
    const mapRef = ref();
    const zoom = ref<number>(13);
    const markerRef = ref();
    const markers = ref<any>([
      {
        position: center,
      },
    ]);

    watch(latitudeW, (current) => {
      if (current) {
        center.lat = parseFloat(current);
      }
    });

    watch(longitudeW, async (current) => {
        if (current) {
          await markers.value.splice(0);
          center.lng = parseFloat(current);
          mapRef.value.map.panTo(center);
          markers.value.push({ position: center });
        }
      },
      { deep: true }
    );

    watch(markers_w, async (current:any) => {
      console.log("markers_w");
      if (current.length > 0) {
        center.lat = current[0].lat;
        center.lng = current[0].lng;
        await markers.value.splice(0);
        await markers.value.push({ position: center });
        zoom.value = 13;
        await mapRef.value.map.panTo(center);
      }
    });

    const getCoors = (coors) => {
      markers.value.splice(0);
      markers.value.push({ position: { lat: coors.lat(), lng: coors.lng() } });
      emit("changeLocation", [coors.lat().toString(), coors.lng().toString()]);
    };

    return {
      center,
      mapRef,
      markerRef,
      getCoors,
      mapsKey,
      markers,
      zoom
    };
  },
});
