
import { defineComponent, ref, toRefs, watch, onMounted } from "vue";
import GoogleMaps from "@/components/google/Maps.vue";
import { ErrorMessage, Form, Field } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SolanaServices from "@/core/services/SolanaServices";
import _ from "lodash";

interface NewAgencyData {
  id?: number;
  name: string;
  email: string;
  phone: string;
  address: string;
  cp: string;
  state: string;
  id_state: any;
  city: string;
  colonia: string;
  url_whatsapp: string;
  latitude: string;
  longitude: string;
}

export default defineComponent({
  name: "NewAgencyModal",
  components: {
    GoogleMaps,
    Form,
    ErrorMessage,
    Field,
  },
  props: {
    agencyId: {
      type: Number,
      required: false,
      default: 0,
    },
    type: {
      type: String,
      required: false,
      default: "new",
    },
  },
  emits: ["finish"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const newAgencyModalRef = ref<null | HTMLElement>(null);
    let latitude = "19.45427274236404";
    let longitude = "-99.13482926287841";
    let longitudeSend = ref<string>("");
    let latitudeSend = ref<string>("");
    const isEdit = ref<boolean>(false);
    let formulario = ref<any>(null);
    const estados = ref<any>({});
    const newMarker = ref<any>({});
    const addresses = ref<any[]>([]);
    const direcciones = ref<string[]>([]);
    const ciudades = ref<string[]>([]);
    const colonias = ref<string[]>([]);
    let agencyId = toRefs(props).agencyId;
    let tipo = toRefs(props).type;
    const tipoModal = ref<string>("new");

    let initialValues = ref<NewAgencyData>({
      id: 0,
      name: "",
      email: "",
      phone: "",
      address: "",
      cp: "",
      id_state: "Estado",
      state: "Estado",
      city: "Ciudad",
      colonia: "Colonia",
      url_whatsapp: "",
      latitude: latitude,
      longitude: longitude,
    });

    watch(agencyId, async (current) => {
      console.log("Tipo", tipo.value);
      console.log(current);
      if (current > 0 && tipo.value == "edit") {
        try {
          let url = process.env.VUE_APP_API_URL + "/api/agencies/" + current;

          const { data } = await axios.get(url);

          if (data.success) {
            initialValues.value = data.data.info;
            if(initialValues.value.address != ''){
              direcciones.value = [initialValues.value.address];
            }
            if(initialValues.value.city != ''){
              ciudades.value = [initialValues.value.city];
            }
            if(initialValues.value.colonia != ''){
              colonias.value = [initialValues.value.colonia];
            }
            if(data.data.info.latitude != '' && data.data.info.longitude){
              latitudeSend.value = data.data.info.latitude.toString();
              longitudeSend.value = data.data.info.longitude.toString();
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    });

    watch(
      () =>initialValues.value.colonia,
      val => {
        if(val == '0' ){
          initialValues.value.colonia = "Escriba la Colonia, por favor...";
        }
        console.log('🚀 ~ file: NewAgencyModal.vue:499 ~ setup ~ colonias.value', val)
      }
    )

    watch(tipo, (current) => {
      console.log(tipo);
      if (current) {
        tipoModal.value = current;
        if (current == "new" || current == "delete") {
          console.log("Entra a delete");
          latitudeSend.value = "19.45427274236404";
          longitudeSend.value = "-99.13482926287841";
          initialValues.value = {
            id: 0,
            name: "",
            email: "",
            phone: "",
            address: "",
            cp: "",
            id_state: 0,
            state: "",
            city: "0",
            colonia: "0",
            url_whatsapp: "",
            latitude: latitude,
            longitude: longitude,
          };
        }else{
          console.log(current)
        }
      }
    });

    const agency = Yup.object().shape({
      name: Yup.string().required("El nombre es obligatorio").label("Nombre"),
      email: Yup.string()
        .required("El correo es obligatorio")
        .label("Correo")
        .email("El correo es no tiene el formato correcto"),
      phone: Yup.string()
        .required("El teléfono es obligatorio")
        .label("Teléfono"),
      address: Yup.string()
        .required("El domicilio es obligatorio")
        .label("Domicilio"),
      cp: Yup.string()
        .required("El código postal es obligatorio")
        .min(5, "Agregue por lo menos 5 dígitos")
        .max(5, "Agregue máximo 5 dígitos")
        .label("Código Postal"),
      state: Yup.string()
        .required("El estado es obligatorio")
        .notOneOf(["0"], "El estado es obligatorio")
        .label("Estado"),
      city: Yup.string()
        .required("La ciudad es obligatorio")
        .notOneOf(["0"], "La ciudad es obligatoria")
        .label("Ciudad"),
      colonia: Yup.string()
        .required("La colonia es obligatorio")
        .notOneOf(["0", "Escriba la Colonia, por favor..."], "La colonia es obligatoria")
        .label("Colonia"),
      url_whatsapp: Yup.string()
        .required("El whatsapp es obligatorio")
        .label("Whatsapp"),
    });

    const clearLocatios = () => {
      //initialValues.value.address = "0";
      initialValues.value.colonia = "0";
      initialValues.value.city = "0";
      direcciones.value = [];
      colonias.value = [];
      ciudades.value = [];
      initialValues.value.cp = "";
      initialValues.value.id_state = 0;
    };

    const setVal = (evt: any) => {
      console.log('🚀 ~ file: NewAgencyModal.vue:593 ~ setVal ~ evt', evt)
      initialValues.value.colonia = "";
      console.log('🚀 ~ file: NewAgencyModal.vue:595 ~ setVal ~ initialValues.value.colonia', initialValues.value.colonia)
    };
    
    const changeLoc = async (evt) => {
      latitude = evt[0];
      longitude = evt[1];
      const data = await SolanaServices.getPlaces(latitude, longitude);
      if(data.status == "OK"){
        initialValues.value.address = "";
        console.log(data.results);
        let ubicaciones = data.results;
        addresses.value = data.results;
        clearLocatios();
        ubicaciones.forEach(element => {
          let direccion = element.formatted_address.split(',');
          if(direccion.length >= 5){
            let direccionFormato = '';
            let addressComponents = element.address_components;
            addressComponents.forEach(components => {
              //Get Number
              if(components.types.includes('street_number')){
                direccionFormato = direccionFormato + components.long_name;
              }
              //Get Calles
              if(components.types.includes('route')){
                direccionFormato = components.long_name + ' ' + direccionFormato + ', ';
              }
              //Get Colonia
              if( (components.types.includes('political') && components.types.includes('sublocality') && components.types.includes('sublocality_level_1')) || (components.types.includes('political') && components.types.includes('neighborhood'))){
                direccionFormato = direccionFormato + components.long_name + ', ';
                if(!colonias.value.includes(components.long_name)){
                  colonias.value.push(components.long_name);
                  initialValues.value.colonia = components.long_name;
                }
              }
              //Get Ciudad
              if(components.types.includes('political') && components.types.includes('locality')){
                direccionFormato = direccionFormato + components.long_name + ', ';
                if(!ciudades.value.includes(components.long_name)){
                  ciudades.value.push(components.long_name);
                  initialValues.value.city = components.long_name;
                }
              }
              //Get Estado
              if(components.types.includes('political') && components.types.includes('administrative_area_level_1')){
                direccionFormato = direccionFormato + components.short_name + ', ';
                if(components.short_name == 'CDMX'){
                  initialValues.value.id_state = 9;
                }else if(components.short_name == 'Méx.'){
                  initialValues.value.id_state = 15;
                }else if(components.short_name == 'Ver.'){
                  initialValues.value.id_state = 30;
                }else if(components.short_name == 'Oax.'){
                  initialValues.value.id_state = 20;
                }else{
                  let arrayStates = estados.value;
                  arrayStates.forEach(estElement => {
                    if(estElement.name == components.long_name){
                      initialValues.value.id_state = estElement.id;
                    }
                  });
                }
              }
              //Get Código Postal
              if(components.types.includes('postal_code')){
                direccionFormato = direccionFormato + 'C.P. ' + components.long_name;
                initialValues.value.cp = components.long_name;
              }
            });
            if(!direcciones.value.includes(direccionFormato)){
              direcciones.value.push(direccionFormato);
            }
          }
        });
        if(direcciones.value.length == 0){
          clearLocatios();
          Swal.fire({
              position: "center",
              icon: "error",
              html: "No hay resultados en su búsqueda",
              showConfirmButton: true,
          });
        }
      }else{
        Swal.fire({
            position: "center",
            icon: "error",
            html: "No hay resultados en su búsqueda",
            showConfirmButton: true,
        });
      }
    };

    const onInvalidSubmit = (values) => {
      console.log(values);
    };

    const save = async (values, { resetForm }) => {
      let dataToSave = {
        name: values.name,
        address: values.address,
        cp: values.cp,
        id_state: values.state,
        city: values.city,
        colonia: values.colonia,
        url_whatsapp: values.url_whatsapp,
        email: values.email,
        phone: values.phone.replace(/-/g, ""),
        latitude: initialValues.value.latitude.toString(),
        longitude: initialValues.value.longitude.toString(),
      };
      if (values.id > 0) {
        let url = process.env.VUE_APP_API_URL + "/api/agencies/" + values.id;
        await axios
          .patch(url, dataToSave)
          .then((response) => {
            if (response.data.code) {
              resetForm();
              hideModal(newAgencyModalRef.value);
              emit("finish", {
                type: "update",
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              let errors = Object.entries(error.response.data.errors);
              let mensaje = '';
              errors.forEach((value, index) =>{
                  mensaje += value[1] + '<br>';
              });
              Swal.fire({
                  position: "center",
                  icon: "error",
                  html: mensaje,
                  showConfirmButton: true,
              });
            }
          });
      } else {
        let url = process.env.VUE_APP_API_URL + "/api/agencies";
        await axios
          .post(url, dataToSave)
          .then((response) => {
            if (response.data.code) {
              resetForm();
              hideModal(newAgencyModalRef.value);
              emit("finish", {
                type: "save",
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              let errors = Object.entries(error.response.data.errors);
              let mensaje = '';
              errors.forEach((value, index) =>{
                  mensaje += value[1] + '<br>';
              });
              Swal.fire({
                  position: "center",
                  icon: "error",
                  html: mensaje,
                  showConfirmButton: true,
              });
            }
          });
      }
    };

    const getEstados = async () => {
      try {
        let url = process.env.VUE_APP_API_URL + "/api/states";
        const { data } = await axios.get(url);
        estados.value = data.data.state;
      } catch (error) {
        console.log(error);
      }
    };

    const descartar = () => {

      emit("finish", {
        type: "close",
      });
    }

    const setFields = (dataAddress) => {
      clearLocatios();
      dataAddress.address_components.forEach(components => {
        //Get Código Postal
        if(components.types.includes('postal_code')){
          initialValues.value.cp = components.long_name;
        }
        //Get Estados
        if(components.types.includes('political') && components.types.includes('administrative_area_level_1')){
          if(components.short_name == 'CDMX'){
            initialValues.value.id_state = 9;
          }else if(components.short_name == 'Méx.'){
            initialValues.value.id_state = 15;
          }else if(components.short_name == 'Ver.'){
            initialValues.value.id_state = 30;
          }else if(components.short_name == 'Oax.'){
            initialValues.value.id_state = 20;
          }else{
            let arrayStates = estados.value;
            arrayStates.forEach(estElement => {
              if(estElement.name == components.long_name){
                initialValues.value.id_state = estElement.id;
              }
            });
          }
        }
        //Get Ciudad
        if(components.types.includes('political') && components.types.includes('locality')){
          if(!ciudades.value.includes(components.long_name)){
            ciudades.value.push(components.long_name);
          }
          initialValues.value.city = components.long_name;
        }
        //Get Colonia
        if( (components.types.includes('political') && components.types.includes('sublocality') && components.types.includes('sublocality_level_1')) || (components.types.includes('political') && components.types.includes('neighborhood'))){
          if(!colonias.value.includes(components.long_name)){
            colonias.value.push(components.long_name);
          }
          initialValues.value.colonia = components.long_name;
        }
      });
      initialValues.value.latitude = dataAddress.geometry.location.lat;
      initialValues.value.longitude = dataAddress.geometry.location.lng;
      newMarker.value = [{
        lat: dataAddress.geometry.location.lat,
        lng: dataAddress.geometry.location.lng,
      }];
    }

    const setMarker = (address) => {
      let addressArray = address.formatted_address.split(',');
      initialValues.value.address = addressArray[0];
      addresses.value = [];
      setFields(address);
    }
    
    const searchAddress = _.debounce(async function () {
      if(initialValues.value.address != ''){
        const data = await SolanaServices.searchPlaces(initialValues.value.address);
        addresses.value = [];
        if(data.results.length > 0){
          addresses.value = data.results;
        }
        
      }      
    }, 500);

    onMounted(async () => {
      await getEstados();
    });

    return {
      formRef,
      initialValues,
      newAgencyModalRef,
      agency,
      longitude,
      latitude,
      onInvalidSubmit,
      save,
      changeLoc,
      latitudeSend,
      longitudeSend,
      isEdit,
      formulario,
      estados,
      getEstados,
      tipoModal,
      descartar,
      direcciones,
      colonias,
      ciudades,
      clearLocatios,
      searchAddress,
      addresses,
      setMarker,
      setFields,
      newMarker,
      setVal
    };
  },
});
