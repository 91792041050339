
import { defineComponent, ref } from "vue";
import axios from "axios";
import { hideModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";


export default defineComponent({
  name: "DeleteAgency",
  components: {},
  props: {
    agencyId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ["finishDelete"],
  setup(props, { emit }) {
    const deleteAgencyModalRef = ref<null | HTMLElement>(null);

    const deleteAgency = async () => {
      let url = process.env.VUE_APP_API_URL + "/api/agencies/" + props.agencyId;
      ApiService.delete(url)
      .then(({data})=> {
        if (data.success) {
          hideModal(deleteAgencyModalRef.value);
          emit("finishDelete", {
            type: "delete",
          });
        }
      })
      .catch(({response})=>{
        hideModal(deleteAgencyModalRef.value);
        Swal.fire({
          title: response.data.message,
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then((errors) => {
          return false;
        });
      });

      /* try {
        let url =
          process.env.VUE_APP_API_URL + "/api/agencies/" + props.agencyId;

        const { data } = await axios.delete(url);

        ApiService.delete(`api/roles/${idToDel}`)

        if (data.success) {
          hideModal(deleteAgencyModalRef.value);
          emit("finishDelete", {
            type: "delete",
          });
        }
      } catch (error) {
        console.log(error);
      } */
    };

    const cancelDelete = () => {
      emit("finishDelete", {
          type: "deleteDismiss",
        });
      };

    return {
      deleteAgencyModalRef,
      deleteAgency,
      cancelDelete
    };
  },
});
