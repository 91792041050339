import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Marker = _resolveComponent("Marker")!
  const _component_GoogleMap = _resolveComponent("GoogleMap")!

  return (_openBlock(), _createBlock(_component_GoogleMap, {
    "api-key": _ctx.mapsKey,
    style: {"width":"100%","height":"45vw"},
    center: _ctx.center,
    zoom: _ctx.zoom,
    ref: "mapRef",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getCoors($event.latLng)))
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markers, (marker, index) => {
        return (_openBlock(), _createBlock(_component_Marker, {
          key: index,
          options: marker,
          draggable: false
        }, null, 8, ["options"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["api-key", "center", "zoom"]))
}